import _ from 'lodash'

export const sortString = function (string) {
  var integer = parseInt(string);

  if (string == String(integer)) {
    return integer;
  } else if (!string) {
    return undefined;
  } else {
    return string.trim().toLowerCase();
  }
};

export const materialGroups = function (materials) {
  var groups = _.groupBy(materials, function (material) {
    return `${material.locationId}___${material.surface}`;
  });

  return _.map(groups, function (materials, key) {
    return {
      location: materials[0]?.locationId,
      surface: materials[0]?.surface,
      materials: materials,
    };
  });
};
