export default (theme) => ({
  toolInfoIcon: {
    width: 15,
    height: 15,
    textAlign: 'center',
    color: '#fff',
    backgroundColor: '#5a87ef',
    position: 'relative',
    borderRadius: '50%',
    cursor: 'pointer',
    fontSize: 12,
    fontWeight: 'bold',
    lineHeight: 1.3,
    marginLeft: 5,
    '&:hover > $passInfoBlock': {
      display: 'block',
    }
  },
  passInfoBlock: {
    display: 'none',
    width: 140,
    backgroundColor: 'white',
    position: 'absolute',
    zIndex: 3,
    color: '#A4A5B3',
    fontWeight: 'normal',
    textAlign: 'left',
    padding: 11,
    borderRadius: 6,
    top: 0,
    left: 20,
    boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.22)',
    '@media screen and (max-width: 999px)': {
      // right: 20px,
      left: 'auto',
    },
  },
  statsRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 4,
  },
  statsDesc: {
    display: 'inline-flex'
  },
  primaryColor: {
    color: theme.palette.textColor.blue,
  },
  blackColor: {
    color: 'black',
  }
});
