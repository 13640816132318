import React, { useEffect, useState, useCallback } from 'react';
import { Box } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { parse } from 'date-fns';

import { apiCalls } from './apiCalls';

import { TimeoffList } from './components';
import EditTimeoffForm from '../../../EditTimeoffForm';
import ConfirmDialog from '../../../ConfirmDialog';
import { styles } from './styles';
import { getKioskOptions } from 'helpers/getOptions';
import { useDateSettingsFormat } from 'common/useDateSettingsFormat';
import { globalBEDateFormat } from 'common/dateFormatConfig';

const useStyles = makeStyles(styles);

const ViewTimeOff = ({ authorizedUser, userId, openSnackbar, setNewPto, user }) => {
  const classes = useStyles();
  const { dateFormat } = useDateSettingsFormat();

  const [timeoffs, setTimeoffs] = useState({ future: [], past: [] });
  const [drawer, setDrawer] = useState({});
  const [modal, setModal] = useState({
    isOpen: false,
    text: '',
    type: 'delete',
    itemId: null,
  });

  const datesComparison = (date) => (new Date(date) - new Date() >= 0 ? 'future' : 'past');

  const openDrawer =
    (timeoff = {}) =>
    () =>
      setDrawer(timeoff);
  const closeDrawer = () => setDrawer({});

  const changeTimeoffs = (type, timeoffId, data) => {
    switch (type) {
      case 'delete': {
        const newTimeOffs = [...timeoffs.future, ...timeoffs.past].filter(
          (timeoff) => timeoff._id !== timeoffId
        );
        setTimeoffs({
          future: newTimeOffs.filter(
            (timeoff) =>
              datesComparison(
                timeoff.utcStartTime
                  ? timeoff.utcStartTime
                  : parse(timeoff.startDate, dateFormat, new Date())
              ) === 'future'
          ),
          past: newTimeOffs.filter(
            (timeoff) =>
              datesComparison(
                timeoff.utcStartTime
                  ? timeoff.utcStartTime
                  : parse(timeoff.startDate, dateFormat, new Date())
              ) === 'past'
          ),
        });
        break;
      }
      case 'updateTimeoff': {
        const newTimeOffs = [...timeoffs.future, ...timeoffs.past].map((timeoff) =>
          timeoff._id === timeoffId ? { ...timeoff, ...data } : timeoff
        );
        setTimeoffs({
          future: newTimeOffs.filter(
            (timeoff) =>
              datesComparison(
                timeoff.utcStartTime
                  ? timeoff.utcStartTime
                  : parse(timeoff.startDate, dateFormat, new Date())
              ) === 'future'
          ),
          past: newTimeOffs.filter(
            (timeoff) =>
              datesComparison(
                timeoff.utcStartTime
                  ? timeoff.utcStartTime
                  : parse(timeoff.startDate, dateFormat, new Date())
              ) === 'past'
          ),
        });
        break;
      }
      default:
        console.log('Timeoffs was not changed..');
        break;
    }
  };

  const openModal = useCallback(
    (type, itemId) => () => {
      const modal = {
        type,
        itemId,
        isOpen: true,
        text: '',
      };
      switch (type) {
        case 'Approved':
          modal.text =
            'Are you sure you want to set the status to approved? This action cannot be undone';
          break;
        case 'Denied':
          modal.text =
            'Are you sure you want to set the status to denied? This action cannot be undone';
          break;
        default:
          modal.text =
            'Are you sure you want to remove this request?  This action cannot be reversed.';
          break;
      }
      setModal(modal);
    },
    [modal]
  );

  const closeModal = () => {
    setModal((prev) => ({ ...prev, isOpen: false }));
  };

  const confirmAction = async () => {
    const { itemId, type } = modal;
    closeModal();
    const body = {
      status: type,
    };
    switch (type) {
      case 'Approved':
        try {
          const res = await apiCalls.updateTimeOff(itemId, body);
          if (res.statusCode > 299) {
            return openSnackbar('error', res.message);
          }
          openSnackbar('success', 'Succesfully approved');
        } catch (err) {
          openSnackbar('error', err.message);
        }
        break;
      case 'Denied':
        try {
          const res = await apiCalls.updateTimeOff(itemId, body);
          if (res.statusCode > 299) {
            return openSnackbar('error', res.message);
          }
          openSnackbar('success', 'Succesfully denied');
        } catch (err) {
          openSnackbar('error', err.message);
        }
        break;
      default:
        try {
          const res = await apiCalls.removeTimeOff(itemId);
          // if (res.statusCode > 299) {
          //   return openSnackbar('error', res.message);
          // }

          // setNewPto(res.summaryPTOHours);
          changeTimeoffs('delete', itemId);
          openSnackbar('success', 'The Time-off was deleted successfully');
        } catch (err) {
          openSnackbar('error', err.message);
        }
        break;
    }
  };

  useEffect(() => {
    let isSubscribed = true;
    const apiCall = async () => {
      try {
        // const options = {
        //   method: 'GET',
        //   headers: {
        //     'X-Auth-Token': localStorage.getItem('authorization-token'),
        //   },
        // };
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/kiosk/timeoffs`, getKioskOptions()).then(
          (res) => res.json()
        );
        if (response.statusCode > 299) throw new Error(response.message);
        if (!isSubscribed) return;
        setTimeoffs({
          future: response.timeoffs?.filter(
            (timeoff) =>
              datesComparison(
                timeoff.utcStartTime
                  ? timeoff.utcStartTime
                  : parse(timeoff.startDate, dateFormat, new Date())
              ) === 'future'
          ),
          past: response.timeoffs?.filter(
            (timeoff) =>
              datesComparison(
                timeoff.utcStartTime
                  ? timeoff.utcStartTime
                  : parse(timeoff.startDate, dateFormat, new Date())
              ) === 'past'
          ),
        });
      } catch (e) {
        console.log(e);
      }
    };
    apiCall();
    return () => (isSubscribed = false);
  }, []);

  return (
    <>
      {timeoffs?.future?.length || timeoffs?.past?.length ? (
        <Box className={classes.blockWrapper}>
          <TimeoffList
            timeoffs={timeoffs.future.sort(
              (a, b) => parse(b.startDate, globalBEDateFormat, new Date()).getTime() - parse(a.startDate, globalBEDateFormat, new Date()).getTime()
            )}
            future
            openModal={openModal}
            openDrawer={openDrawer}
          />
          <TimeoffList
            timeoffs={timeoffs.past.sort(
              (a, b) => parse(b.startDate, globalBEDateFormat, new Date()).getTime() - parse(a.startDate, globalBEDateFormat, new Date()).getTime()
            )}
            openModal={openModal}
            openDrawer={openDrawer}
          />
        </Box>
      ) : (
        <Typography variant="h3" align="center" style={{ margin: '0.8em 0 2em', opacity: 0.5 }}>
          No Results
        </Typography>
      )}
      <Drawer anchor="right" open={!!Object.keys(drawer).length}>
        <EditTimeoffForm
          authorizedUser={authorizedUser}
          closeDrawer={closeDrawer}
          closeEdit={closeDrawer}
          closeRemove={closeDrawer}
          openSnackbar={openSnackbar}
          timeoff={drawer}
          changeTimeoffs={changeTimeoffs}
          setNewPto={setNewPto}
          remoteUser={user}
        />
      </Drawer>
      <ConfirmDialog
        isOpen={modal.isOpen}
        onClose={closeModal}
        onSubmit={confirmAction}
        text={modal.text}
      />
    </>
  );
};

export default ViewTimeOff;
