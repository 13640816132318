import timeclocksPageActions from './timeclocksPageActions';
import $api from 'http/index';
import { getOptions } from 'helpers/getOptions';
import { downloadFile } from 'helpers/downloadFile';
import { format, parse } from 'date-fns';
import _ from 'lodash';
import { getAdjacentUsers } from 'api/_utility';
import peoplePageActions from 'store/people/peoplePageActions';
import { dateSettingsFormat, globalBEDateFormat } from 'common/dateFormatConfig';

export const enqueueSnackbar = (notification) => {
  const key = notification.options && notification.options.key;

  // return {
  //   type: ENQUEUE_SNACKBAR,
  //   notification: {
  //     ...notification,
  //     key: key || new Date().getTime() + Math.random(),
  //   },
  // };
  return {
    ...notification,
    key: key || new Date().getTime() + Math.random(),
  };
};

export const exportExcel = () => async (dispatch, getState) => {
  const state = getState().timeclocks;
  const weekly = state.tabs.find((t) => t.index === state.activeTab).name === 'by-individual-week';

  const startDate = weekly
    ? state.weekSummary.exported.period.from
    : state.summary.exported.period.from;
  const endDate = weekly ? state.weekSummary.exported.period.to : state.summary.exported.period.to;
  let filename = `${format(startDate, 'M-d-yy')}_${format(endDate, 'M-d-yy')}.xlsx`;

  const searchParams = new URLSearchParams();
  searchParams.append('startDate', format(startDate, globalBEDateFormat));
  searchParams.append('endDate', format(endDate, globalBEDateFormat));

  if (weekly) {
    const { user } = state.weekSummary.exported;

    searchParams.append('isByIndividualWeek', 'true');
    searchParams.append('getByUserIds', user._id);

    const userName =
      user && user.profile ? `${user.profile.fullName} #${user.profile.employeeNum} ` : '';
    filename = `${userName}` + filename;
  }

  const response = await $api.get(
    `${process.env.REACT_APP_BASE_URL}/timeclock-summary/generate-excel`,
    {
      params: searchParams,
      ...getOptions('text/xlsx'),
      responseType: 'blob',
    }
  );

  downloadFile(response.data, filename);

  dispatch(timeclocksPageActions.setSummary({ ...state.summary, exported: null }));
  dispatch(timeclocksPageActions.setWeekSummary({ ...state.weekSummary, exported: null }));
};

export const verifySummary = (date, data) => (dispatch) => {
  const Snackbar = (...args) =>
    dispatch(timeclocksPageActions.enqueueSnackbar(enqueueSnackbar(...args)));

  return $api
    .post(`${process.env.REACT_APP_BASE_URL}/payrolls/verify`, data, getOptions())
    .then((response) => {
      Snackbar({
        message: 'Successfully Verified!',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
        },
      });
      // dispatch(timeclocksPageActions.setPayrollVerified({ date, data: response.data }));
      dispatch(getWeekTimeclockSummary(data.userId));
    })
    .catch((error) => {
      Snackbar({
        message: 'Error while verifying',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
        },
      });
      throw error;
    });
};

export const reconcilePayroll = (payrollId, date, data) => (dispatch) => {
  const Snackbar = (...args) =>
    dispatch(timeclocksPageActions.enqueueSnackbar(enqueueSnackbar(...args)));

  return $api
    .put(`${process.env.REACT_APP_BASE_URL}/payrolls/reconcile/${payrollId}`, data, getOptions())
    .then((response) => {
      Snackbar({
        message: 'Successfully Reconciled!',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          autoHideDuration: 2000,
        },
      });
      // dispatch(timeclocksPageActions.setPayrollReconciled({ date, data: response.data }));
      dispatch(getWeekTimeclockSummary(data.userId));
    })
    .catch((error) => {
      Snackbar({
        message: 'Error while reconciling',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          autoHideDuration: 2000,
        },
      });
      throw error;
    });
};

export const getTimeclockSummary =
  ({ date, getMachineShop, getSubmitted, userId }) =>
  async (dispatch, getState) => {
    const state = getState().timeclocks;

    dispatch(timeclocksPageActions.dis(true));
    const options = {
      ...getOptions(),
      params: {},
    };

    try {
      options.params.date = format(new Date(date), globalBEDateFormat);
      if (getMachineShop) options.params.getMachineShop = 'true';
      if (getSubmitted) options.params.getSubmitted = 'true';
      if (userId) options.params.userId = userId;

      const res = await $api.get(
        `${process.env.REACT_APP_BASE_URL}/timeclock-summary/all`,
        options
      );

      if (userId) {
        const summaryData = state.summary.data.map((summaryObject) =>
          summaryObject.user._id === userId ? res.data[0] : summaryObject
        );
        dispatch(
          timeclocksPageActions.setSummary({
            ...state.summary,
            data: summaryData.filter((summaryObject) => !!summaryObject),
          })
        );
      } else {
        dispatch(timeclocksPageActions.setSummary({ ...state.summary, data: res.data }));
      }
      dispatch(timeclocksPageActions.setLoading(false));
      dispatch(timeclocksPageActions.dis(false));
    } catch (error) {
      console.log(error);
    }
  };

export const postCreateTimeclockTime =
  (data, userId, isByIndividualWeek) => async (dispatch, getState) => {
    const { summary } = getState().timeclocks;

    await $api.post(`${process.env.REACT_APP_BASE_URL}/timeclock`, data, getOptions());

    if (isByIndividualWeek) {
      dispatch(getWeekTimeclockSummary(userId));
    } else {
      dispatch(getTimeclockSummary({ date: summary.query.date, userId: userId }));
    }
  };

export const patchEditTimeclockTime =
  (timeclockId, data, userId, isByIndividualWeek) => async (dispatch, getState) => {
    const { summary } = getState().timeclocks;

    const res = await $api.patch(
      `${process.env.REACT_APP_BASE_URL}/timeclock/${timeclockId}/time`,
      data,
      getOptions()
    );

    if (isByIndividualWeek) {
      dispatch(getWeekTimeclockSummary(userId));
    } else {
      dispatch(getTimeclockSummary({ date: summary.query.date, userId: userId }));
    }
  };

export const deleteTimeclockTime =
  (timeclockId, userId, isByIndividualWeek) => async (dispatch, getState) => {
    const { summary } = getState().timeclocks;

    await $api.delete(`${process.env.REACT_APP_BASE_URL}/timeclock/${timeclockId}`, getOptions());

    if (isByIndividualWeek) {
      dispatch(getWeekTimeclockSummary(userId));
    } else {
      dispatch(getTimeclockSummary({ date: summary.query.date, userId: userId }));
    }
  };

export const patchEditLunchTime =
  (timeclockId, data, userId, isByIndividualWeek) => async (dispatch, getState) => {
    const { summary } = getState().timeclocks;

    await $api.patch(
      `${process.env.REACT_APP_BASE_URL}/timeclock/${timeclockId}/lunch-time`,
      data,
      getOptions()
    );

    if (isByIndividualWeek) {
      dispatch(getWeekTimeclockSummary(userId));
    } else {
      dispatch(getTimeclockSummary({ date: summary.query.date, userId: userId }));
    }
  };

export const refreshTimeClock =
  ({ getMachineShop, getSubmitted, byIndividual }) =>
  async (dispatch, getState) => {
    const { users, summary } = getState().timeclocks;

    if (!!byIndividual) {
      dispatch(getWeekTimeclockSummary(users?.current?._id));
    }

    const queryParams = { date: summary.query.date, getMachineShop, getSubmitted };
    dispatch(getTimeclockSummary(queryParams));
  };

export const deleteLunchTime =
  (timeclockId, userId, isByIndividualWeek) => async (dispatch, getState) => {
    const { summary } = getState().timeclocks;

    try {
      await $api.delete(
        `${process.env.REACT_APP_BASE_URL}/timeclock/${timeclockId}/lunch-time`,
        getOptions()
      );

      if (isByIndividualWeek) {
        dispatch(getWeekTimeclockSummary(userId));
      } else {
        dispatch(getTimeclockSummary({ date: summary.query.date, userId: userId }));
      }
    } catch (error) {
      console.log(error);
    }
  };

export const getWeekTimeclockSummary = (userId) => async (dispatch, getState) => {
  const { weekSummary } = getState().timeclocks;
  const { dateFormat: settingFormat } = getState().personalProfile.organization?.settings;

  const options = {
    ...getOptions(),
    params: {},
  };
  const queryDate = weekSummary?.query?.period.split('..')[0];

  if (queryDate) {
    options.params.date = format(
      parse(queryDate, dateSettingsFormat(settingFormat).dateFormat, new Date()),
      globalBEDateFormat
    );
  } else {
    options.params.date = format(new Date(), globalBEDateFormat);
  }

  if (userId) options.params.userId = userId;

  try {
    dispatch(timeclocksPageActions.dis(true));
    const res = await $api.get(`${process.env.REACT_APP_BASE_URL}/timeclock-summary/week`, options);
    dispatch(
      timeclocksPageActions.setWeekSummary({
        ...weekSummary,
        data: res.data,
      })
    );
    dispatch(timeclocksPageActions.setLoading(false));
    dispatch(timeclocksPageActions.dis(false));
  } catch (error) {
    console.log(error);
  }
};

export const fetchTimeclockUsers = () => async (dispatch, getState) => {
  const { weekSummary } = getState().timeclocks;
  const personalProfile = getState().personalProfile;

  const options = {
    ...getOptions(),
    params: {
      skip: 0,
      limit: Infinity,
    },
  };

  try {
    const res = await $api.get(`${process.env.REACT_APP_BASE_URL}/users?isTimeclock=true`, options);

    let userIndex = 0;

    if (weekSummary.query.userId) {
      const chosenUserId = res.data.findIndex((user) => user._id === weekSummary.query.userId);

      if (chosenUserId !== -1) {
        userIndex = chosenUserId;
      }
    } else if (personalProfile?.user?._id) {
      const personalUserIndex = res.data.findIndex((user) => user._id === personalProfile.user._id);

      if (personalUserIndex !== -1) {
        userIndex = personalUserIndex;
      }
    }

    const users = {
      current: {
        ...res.data[userIndex],
        userIndex: userIndex,
      },
    };

    const { prev, next } = getAdjacentUsers(res.data, users.current);
    users.previous = prev;
    users.next = next;

    dispatch(timeclocksPageActions.setUsers(users));
    dispatch(peoplePageActions.getUsersPeoplePageData(res.data));
  } catch (error) {
    console.log(error);
  }
};
